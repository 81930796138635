import ComponentStore from 'components/data/ComponentStore';
import User from 'components/data/User';
import { Brick } from '../types/brick.type';
import { BricksComponentStore, CSBrickPublishJob, CSPublishExecutionPanel, CSPublishExecutionPanelJob, PublishJobs } from '../types/bricksComponentStore.type';
import { JobStatus } from '../types/publish.type';
import { BrickPublishJob } from '../types/brick-publish.type';
import BrickDataService from '../services/brick-data.service';
import { BrickPublishJobData } from '../hooks/useBricksPublish';
import BricksComponentStoreHelper from './bricks-component-store.helper';

class PublishHelpers {
    /**
     * Handles the tasks that need to be done after a publish status is changed
     * @param bricks The bricks to update
     * @param status The new status for these bricks
     * @returns
     */
    static handleBrickPublishStatusUpdate = (bricks: Brick | Brick[], jobId?: string): void => {
        if (!bricks) return;

        if (!Array.isArray(bricks)) bricks = [bricks];
        const brickIds = bricks.map((brick) => brick.id);
        PublishHelpers.updateExecutionPanelBricks(brickIds, 'add', jobId);
    };

    static handleAddBricksPublishJob = (bricks: Brick | Brick[], jobId: string, publishId: string, publishProfile = 'default'): BrickPublishJob => {
        if (!Array.isArray(bricks)) bricks = [bricks];

        const createdAt = new Date().toISOString();
        const publishJob: BrickPublishJob = {
            createdAt,
            status: JobStatus.WAITING,
            jobToken: jobId,
            publishId,
            failedTasks: 0,
            finishedTasks: 0,
            totalTasks: 0,
            createdBy: Number(User.get('id'))
        };

        bricks.forEach((brick) => {
            const publishJobs = brick.publish && brick.publish[publishProfile] ? brick.publish[publishProfile] : [];
            publishJobs.unshift(publishJob);
            BricksComponentStoreHelper.setBrickModel(brick.id, `publish.${publishProfile}`, publishJobs, { disableMultiEdit: true });
        });

        return publishJob;
    };

    /**
     * Updates the bricks in the execution panel. Get the current array of brick ids and adds the new brick ids to it.
     * @param bricks The bricks to add to the execution panel
     */
    static updateExecutionPanelBricks = (brickIds: string[] = [], action: 'add' | 'remove', jobId?: string): void => {
        const currentJobs = (ComponentStore.get('Bricks') as BricksComponentStore).publishExecutionPanel.jobs || [];

        if (action === 'add') {
            if (!jobId) return;
            const executionPanelJobs: CSPublishExecutionPanelJob[] = [...currentJobs, { jobId, brickIds }];
            const publishExecutionPanel: CSPublishExecutionPanel = { jobs: executionPanelJobs, open: true };

            ComponentStore.setModel('Bricks', 'publishExecutionPanel', publishExecutionPanel);
        } else {
            // Remove jobs containing any of the bricks to be deleted
            const updatedJobs = currentJobs.filter(({ brickIds }) => !brickIds.some((brickId) => brickIds.includes(brickId)));

            const publishExecutionPanel: CSPublishExecutionPanel = { jobs: updatedJobs, open: true };
            ComponentStore.setModel('Bricks', 'publishExecutionPanel', publishExecutionPanel);
        }
    };

    static getPublishStatus(
        publishJobs?: BrickPublishJobData[],
        validationErrors?: Array<unknown>
    ): 'readyToPublish' | 'success' | 'publishing' | 'error' | 'info' | 'blocking' | 'canceled' {
        // if all the publisjobs are finished return success
        // if all the publisjobs are waiting return publishing
        // if all the publisjobs are working return publishing
        // if all the publisjobs are failed return error
        // if there is a mix of statuses return info;

        const jobStatusses = publishJobs?.map((job) => job.status);
        const uniqueStatuses = Array.from(new Set(jobStatusses));

        const status = (() => {
            if (uniqueStatuses.length === 1) {
                if (uniqueStatuses[0] === JobStatus.FINISHED) {
                    // if one of the publish jobs has errors return info
                    if (publishJobs?.some((job) => job.errors?.length)) return 'info';

                    return 'success';
                }
                if (uniqueStatuses[0] === JobStatus.WAITING) return 'publishing';
                if (uniqueStatuses[0] === JobStatus.WORKING) return 'publishing';
                if (uniqueStatuses[0] === JobStatus.FAILED) {
                    // if one of the publish jobs has products return info
                    if (publishJobs?.some((job) => job.products?.length)) return 'info';

                    return 'error';
                }
            }
        })();

        if (status) return status;

        if (validationErrors && validationErrors.length > 0) return 'blocking';
        if (!publishJobs) return 'readyToPublish';
        if (publishJobs.length === 0) return 'readyToPublish';
        if (publishJobs.some((job) => job.status === JobStatus.CANCELED)) return 'canceled';

        return 'info';
    }

    static groupBricksByRunningJobId = (bricks: Brick[]): CSPublishExecutionPanelJob[] => {
        const groupedBricks: Record<string, string[]> = {};

        // Get running jobIds for the bricks
        const runningJobs = bricks.reduce((acc, brick) => {
            const runningJob = brick.publish?.default.find((job) => job.status === JobStatus.WORKING || job.status === JobStatus.WAITING);
            if (runningJob && !acc.includes(runningJob.jobToken)) acc.push(runningJob.jobToken);
            return acc;
        }, [] as string[]);

        // Loop through the bricks to check if they have the running job
        bricks.forEach((brick) => {
            brick.publish?.default?.forEach((job) => {
                const jobId = job.jobToken;
                if (runningJobs.includes(jobId)) {
                    if (!groupedBricks[jobId]) {
                        groupedBricks[jobId] = [];
                    }
                    groupedBricks[jobId].push(brick.id);
                }
            });
        });

        return Object.entries(groupedBricks).map(([jobId, brickIds]) => ({
            jobId,
            brickIds
        }));
    };

    /**
     * Check if any bricks are job which is publishing
     * @param bricks
     */
    static checkBricksPublishStatus(): void {
        const publishingBricks: Brick[] = [];
        BrickDataService.getPublishingBricks().then((bricks: Brick[] | undefined) => {
            if (!bricks || !bricks.length) return;

            Object.values(bricks).forEach((brick: Brick) => {
                if (brick.publish && brick.publish.default && brick.publish.default.length) publishingBricks.push(brick);
            });

            const runningJobs = this.groupBricksByRunningJobId(publishingBricks);

            runningJobs.forEach(({ brickIds, jobId }) => {
                if (publishingBricks.length) this.updateExecutionPanelBricks(brickIds, 'add', jobId);
            });
        });
    }

    /**
     * Filter out the download jobs that are not from the user
     */
    static getJobsFromUser = (jobs: CSBrickPublishJob[], publish: PublishJobs): CSBrickPublishJob[] => {
        return jobs.filter((job: CSBrickPublishJob) => {
            const brickJobs = Object.keys(job.bricks).map((brickId) => brickId);
            const brickId = brickJobs[0];
            const outputAction = job.bricks[brickId].outputAction;
            const createdBy = job.bricks[brickId]?.createdBy || publish[job.jobToken]?.bricks[brickId]?.createdBy;
            const isJobFromUser = createdBy === Number(User.get('id'));

            // Filter out the download jobs that are not from the user
            if (outputAction === 'download' && !isJobFromUser) return false;
            return true;
        });
    };
}

export default PublishHelpers;
