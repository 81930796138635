import moment from 'moment';
import ComponentStoreHelpers from 'components/data/ComponentStore';
import EditorData from 'components/editor-data/EditorData';
import { Brick } from '../types/brick.type';
import BrickHelpers from './brick.helpers';
import PublishService, { PublishResponse } from '../services/publish.service';
import { DownloadDetails } from '../types/bricksComponentStore.type';
class DownloadHelpers {
    static async downloadBrickStructure(brick: Brick): Promise<PublishResponse | undefined> {
        const brickStructure = BrickHelpers.getBrickStructure(brick);

        return await this.downloadSelectedBricks(brickStructure);
    }

    static async downloadSelectedBricks(bricks: Brick[]): Promise<PublishResponse | undefined> {
        const archiveName = this.getArchiveName();
        const result = await PublishService.downloadBricks(bricks, archiveName);
        const id = result?.publishId;

        if (id) this.setDownloadStatus(bricks, id, archiveName, result.jobId);

        return result;
    }

    private static setDownloadStatus = (bricks: Brick[], publishId: string, archiveName: string, jobId?: string): void => {
        const downloadStatus: DownloadDetails = {
            bricksCount: bricks.length,
            archiveName,
            brickIds: bricks.map((brick) => brick.id),
            jobId
        };

        ComponentStoreHelpers.setMultiModels('Bricks', [
            ['publishExecutionPanel.open', true],
            [`download.${publishId}`, downloadStatus]
        ]);
    };

    public static downloadProductsForBricks = async (bricks: Brick[], publishId?: string): Promise<string | undefined> => {
        const archiveName = this.getArchiveName();
        return await PublishService.downloadBrickProducts(bricks, publishId, archiveName);
    };

    private static getArchiveName = () => {
        const date = moment().format('DD-MM-YYYY-HH:mm:ss');

        const name = `assets-export-${date}`;
        return name;
    };
}

export default DownloadHelpers;
