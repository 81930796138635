import axios, { isAxiosError } from 'axios';
import VideoService from 'services/video/video.service';
import * as Sentry from '@sentry/react';
import get from 'lodash/get';
import User from 'components/data/User';
import { CreativeV2Media } from 'components/creatives-v2/components/creative-editor/types/creativeV2.type';
import { SourceData } from 'components/assets/AssetGalleryDialog/interfaces/AssetGalleryDialogState';
import { Brick, FileExt, FileType } from '../types/brick.type';
import { AssetRestrictionsKeys, Preset } from '../types/preset';
import AssetRestrictionsHelper from './asset-restriction.helpers';
import { MODEL_CREATIVES } from '../constants';
import { getValidFileType } from '../components/shared/components/creative-input/helpers/asset.helpers';
export interface ValidationResults {
    errors: AssetMetadataFeedback[];
    warnings: AssetMetadataFeedback[];
}
export interface AssetMetadataFeedback {
    type: AssetRestrictionsKeys | string;
    message?: string;
}

interface ValidationBrickMatch {
    brick: Brick;
    validationResults: ValidationResults;
}

export interface AssetMetadata {
    id?: number;
    fileName?: string;
    extension?: string;
    size?: number;
    progress?: number;
    warnings?: AssetMetadataFeedback[];
    errors?: AssetMetadataFeedback[];
    title?: string;
    openAssetEditor?: boolean;
    originalImage?: string;
    humanSize?: string;
    key?: string;
    subtitle?: string;
    status?: string;
    width?: number;
    height?: number;
    url?: string;
    thumbnail?: string;
    type?: 'image' | 'video';
    aspectRatio?: string;
    customAspectRatio?: string;
    fileExtension?: FileExt;
    fileMime?: string;
    filesize?: number;
    humanFileSize?: string;
    fileType?: string;
    duration?: number;
    imageCompressorState?: unknown;
    backgroundRemoverState?: unknown;
    assetData?: unknown;
    colorMode?: string;
    codec?: string;
    frameRate?: number;
    sampleRate?: number;
    numberOfPages?: number;
    pageWidth?: number;
    pageHeight?: number;
    maxFileSize?: number;
    zipUrl?: string;
}

class AssetHelpers {
    /**
     * Gets the asset metadata based on the provided source data that comes from the file upload.
     * @param sourceData File upload source data.
     */
    static async getAssetMetadataBySourceData(sourceData: SourceData): Promise<AssetMetadata | null> {
        const validFileType = getValidFileType(sourceData.fileType);
        return await AssetHelpers.getAssetMetadata(sourceData.url, sourceData.extension as FileExt, validFileType);
    }

    public static async getAssetMetadata(assetUrl: string, fileExtension: FileExt, fileType: FileType): Promise<AssetMetadata | null> {
        let assetMetadata: AssetMetadata | null = null;

        if (fileType === 'zip') {
            assetMetadata = await AssetHelpers.validateHTML5Zip(assetUrl, fileExtension);
        } else {
            assetMetadata = await AssetHelpers.getMediaMetadataWithThumbnail(assetUrl, fileExtension, fileType);
        }

        if (!assetMetadata) return null;

        return { ...assetMetadata, fileType };
    }

    public static async validateHTML5Zip(assetUrl: string, fileExtension: FileExt): Promise<AssetMetadata> {
        const input = {
            ads: [
                {
                    url: assetUrl,
                    generateBackup: true
                }
            ]
        };

        let result: any = null;
        try {
            result = await axios.post(process.env.APP_MEDIA_URL + 'media/checkhtml5zip', input, {
                headers: { Authorization: `Bearer ${User.get('mediaServicesApiToken')}` }
            });
        } catch (error) {
            if (isAxiosError(error)) {
                if (error.response?.status === 400) {
                    result = error.response?.data.message;
                }
            }

            if (!result) result = 'The uploaded zip file is incorrect.';
        }

        if (typeof result === 'string') {
            return {
                errors: [{ type: 'html5', message: result }],
                warnings: [],
                url: assetUrl,
                fileExtension
            };
        }

        if (!result.data || !result.data.success) {
            return {
                errors: [{ type: 'upload', message: 'The uploaded zip file is incorrect.' }],
                warnings: [],
                url: assetUrl,
                fileExtension
            };
        }

        const parsedHTML5Warnings = result.data.warnings?.map((warning: string) => {
            return { type: 'html5', message: warning };
        });

        const parsedHTML5Errors = result.data.errors?.map((error: string) => {
            return { type: 'html5', message: error };
        });

        return {
            warnings: parsedHTML5Warnings || [],
            errors: parsedHTML5Errors || [],
            width: result.data.ads[0].width,
            height: result.data.ads[0].height,
            url: result.data.ads[0].url,
            thumbnail: result.data.ads[0].backupImage,
            zipUrl: assetUrl,
            aspectRatio: AssetHelpers.calculateAspectRatio(result.data.ads[0].width, result.data.ads[0].height),
            fileExtension: 'zip'
        };
    }

    /**
     * Get the metadata of a media file with the thumbnail.
     * @param assetUrl URL of the media file.
     * @param fileExtension File extension of the media file.
     * @returns Metadata of the media file with the thumbnail.
     */
    static async getMediaMetadataWithThumbnail(assetUrl: string, fileExtension: FileExt, fileType: FileType): Promise<AssetMetadata> {
        const metadata = await AssetHelpers.getMediaMetadata(assetUrl, fileExtension); // Get the asset metadata.

        if (fileType === 'video') {
            const thumbnail = await VideoService.getVideoThumbnail(assetUrl); // Get the thumbnail of the video.

            return { ...metadata, thumbnail }; // Return the asset metadata with the thumbnail.
        }

        return metadata; // Return the asset metadata.
    }

    public static async getMediaMetadata(assetUrl: string, fileExtension: FileExt): Promise<AssetMetadata> {
        try {
            const token = User.get('mediaServicesApiToken');

            const response = await axios.post(
                process.env.APP_MEDIA_URL + 'media/mediaDetails',
                { url: assetUrl },
                { headers: { Authorization: `Bearer ${token}` } }
            );

            return {
                warnings: [],
                errors: [],
                width: response.data.width,
                height: response.data.height,
                url: assetUrl,
                thumbnail: assetUrl,
                aspectRatio: response.data.aspectRatio,
                fileExtension: response.data.fileExtension,
                fileMime: response.data.fileMime,
                duration: response.data.duration,
                humanFileSize: response.data.humanFileSize,
                filesize: response.data.filesize
            };
        } catch (error) {
            Sentry.captureException(error);

            return {
                errors: [],
                warnings: [],
                url: assetUrl,
                fileExtension
            };
        }
    }

    /**
     * Find an existing asset brick that has a valid preset based on the provided asset metadata.
     * A valid preset brick will have no errors or the least warnings based on the asset metadata.
     * @param bricks Array of bricks to search through.
     * @param assetMetadata Metadata of the asset to match with the brick.
     * @returns The brick that has a valid preset based on the provided asset metadata or undefined if no brick is found.
     */
    static findValidPresetBrick(bricks: Brick[], assetMetadata: AssetMetadata): ValidationBrickMatch | undefined {
        const possibleValidBricks: ValidationBrickMatch[] = [];

        for (const singleAssetBrick of bricks) {
            if (!singleAssetBrick.data || !singleAssetBrick.data.presets) continue;
            const preset: Preset | undefined = singleAssetBrick.data.presets?.[0];
            const creatives: CreativeV2Media[] | undefined = get(singleAssetBrick, MODEL_CREATIVES);

            if (!preset || (creatives && creatives.length > 0)) continue; // Skip the brick if there is no preset or if there are creatives.

            const validateResults = AssetRestrictionsHelper.validateAssetMetadataByRestrictions(assetMetadata, preset.restrictions, preset.recommendations);

            if (!validateResults.errors.length) {
                possibleValidBricks.push({ brick: singleAssetBrick, validationResults: validateResults });
            }
        }

        return this.findBrickWithLeastWarnings(possibleValidBricks);
    }

    /**
     * Finds the brick with the least number of warnings.
     *
     * @param bricks - An array of ValidationBrickMatch objects to search through.
     * @returns The ValidationBrickMatch object with the least number of warnings.
     */
    static findBrickWithLeastWarnings(bricks: ValidationBrickMatch[]): ValidationBrickMatch | undefined {
        return bricks.reduce((leastWarningsBrick, currentBrick) => {
            const currentBrickWarnings = currentBrick.validationResults.warnings.length;
            const leastBrickWarnings = leastWarningsBrick.validationResults.warnings.length;

            return currentBrickWarnings < leastBrickWarnings ? currentBrick : leastWarningsBrick;
        }, bricks[0]);
    }

    /**
     * Calculate the aspect ratio of a media file.
     * @param width Width of the media file.
     * @param height Height of the media file.
     * @returns Aspect ratio in the format 'width:height'.
     */
    private static calculateAspectRatio(width: number, height: number): string {
        const gcd = this.greatestCommonDivisor(width, height);
        return `${width / gcd}:${height / gcd}`;
    }

    /**
     * Converts the aspect ratio string to a number.
     * @param aspectRatio Aspect ratio string to convert.
     * @returns Aspect ratio as a number.
     */
    static convertAspectRatio(aspectRatio: string): number {
        const [width, height] = aspectRatio.split(':').map(Number);
        return width / height;
    }

    /**
     * Calculate the greatest common divisor (GCD) of two numbers.
     * @param a First number.
     * @param b Second number.
     * @returns GCD of the two numbers.
     */
    private static greatestCommonDivisor(a: number, b: number): number {
        return b === 0 ? a : this.greatestCommonDivisor(b, a % b);
    }
}

export { AssetHelpers };
