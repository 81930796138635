import set from 'lodash/set';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import Request from 'components/data/Request';
import Resources from 'components/data/Resources/components';

const BRICKS_STATE_NAME = 'bricks_state';
const CUSTOM_PRESETS_STATE_NAME = 'custom_presets';

interface ResourceOverviewResponse {
    data: ResourceOverview[];
}
interface ResourceOverview {
    id: string;
    resource: string;
}

class BricksStateHelpers {
    /**
     * Saves value in bricks state and if not exists creates new bricks state
     * @param value
     * @param id
     */
    private static saveBricksState(value = {}, id?: string): void {
        Request.post('resources/save', {
            id,
            data: { id, resource: BRICKS_STATE_NAME, data: JSON.stringify(value) }
        });
        Resources.set(BRICKS_STATE_NAME, value);
    }

    /**
     * Sets value in bricks state
     * @param campaignId
     * @param path
     * @param value
     * @returns
     */
    static async setBricksState(campaignId: string, path: string, value: unknown): Promise<void> {
        const bricksState = Resources.get(BRICKS_STATE_NAME);

        // Patch of value in bricks state
        const valuePath = `bricks_${campaignId}_state.${path}`;

        // Checks if there is no bricks state or it is empty and creates a new one
        if (!bricksState || isEmpty(bricksState)) {
            const newValue = {};
            set(newValue, valuePath, value);
            return this.saveBricksState(newValue);
        }

        // Sets new value in bricks state
        set(bricksState, valuePath, value);

        // Gets all resources
        const response: ResourceOverviewResponse = await Request.post('resources/overview');

        if (!response || !response.data) return;
        // Fetch the resource ID for bricks if it exists
        try {
            const resourceId = await this.fetchResourceId(BRICKS_STATE_NAME);
            if (resourceId) {
                this.saveBricksState(bricksState, resourceId);
                Resources.set(BRICKS_STATE_NAME, bricksState);
            }
        } catch (error) {
            console.error('Error updating bricks state:', error);
        }
    }

    /**
     * Gets value from bricks state
     * @param campaignId
     * @param path
     * @returns value from bricks state
     */
    static getFromBricksState(campaignId: string, path: string): any {
        const bricksState = Resources.get(BRICKS_STATE_NAME);

        if (!bricksState) return;

        const result = get(bricksState, `bricks_${campaignId}_state.${path}`);

        return result;
    }

    /**
     * Gets campaign brick state
     * @param campaignId
     * @returns campaign brick state
     */
    static getCampaignBricksState(campaignId: string): any {
        const bricksState = Resources.get(BRICKS_STATE_NAME);

        if (!bricksState) return;

        return bricksState[`bricks_${campaignId}_state`];
    }

    /**
     * Gets bricks state
     * @returns bricks state
     */
    static getBricksState(): any {
        return Resources.get(BRICKS_STATE_NAME);
    }

    /**
     * Saves value in custom presets state and if not exists creates new custom presets state
     * @param value
     * @param id
     */
    private static saveCustomPresetsState(value = {}, id?: string): void {
        try {
            Request.post('resources/save', {
                id,
                data: { id, resource: CUSTOM_PRESETS_STATE_NAME, data: JSON.stringify(value) }
            });
            Resources.set(CUSTOM_PRESETS_STATE_NAME, value);
        } catch (error) {
            console.error('Error saving custom presets:', error);
        }
    }

    /**
     * Sets a value in the custom presets state.
     * If the custom presets state is empty or missing, creates a new state with the specified path and value.
     * @param path Path inside the custom presets state to set the value at.
     * @param value Value to set within the custom presets state.
     */
    static async setCustomPresetsState(path: string, value: any) {
        const customPresetsState = Resources.get(CUSTOM_PRESETS_STATE_NAME) || {};

        // Set the value at the given path
        set(customPresetsState, path, value);

        // Fetch the resource ID for custom presets if it exists
        try {
            const resourceId = await this.fetchResourceId(CUSTOM_PRESETS_STATE_NAME);
            if (resourceId) {
                this.saveCustomPresetsState(customPresetsState, resourceId);
                Resources.set(CUSTOM_PRESETS_STATE_NAME, customPresetsState);
            }
        } catch (error) {
            console.error('Error updating custom presets state:', error);
        }
    }

    /**
     * Fetches the resource ID for a given resource name.
     * @param resourceName The name of the resource to find.
     * @returns {Promise<string | undefined>} The resource ID if found, otherwise undefined.
     */
    private static async fetchResourceId(resourceName: string): Promise<string | undefined> {
        try {
            const response: ResourceOverviewResponse = await Request.post('resources/overview');
            if (response && response.data) {
                const resource = response.data.find((res) => res.resource === resourceName);
                return resource?.id;
            }
        } catch (error) {
            console.error(`Error fetching resource ID for ${resourceName}:`, error);
        }
    }
}

export default BricksStateHelpers;
