import { Brick, BrickOutputAction, BrickSetup } from '../types/brick.type';
import BrickHelpers from './brick.helpers';

/** Helper class for setup bricks. */
class BrickSetupHelper {
    /**
     * Get the title of the setup brick.
     * @param brick The brick to get the setup title for.
     * @returns The title of the setup brick.
     */
    static getSetupBrickTitle(brick: Brick): string {
        const setup: BrickSetup = BrickHelpers.getBrickData(brick.subType, 'setup');
        return setup?.title;
    }

    /**
     * Gets the output action from the brick setup based on the provided brick id.
     */
    static getOutputAction = (brickId: string): BrickOutputAction | undefined => {
        const brick = BrickHelpers.getBrickById(brickId);
        const setup = BrickHelpers.getBrickData<BrickSetup | undefined>(brick?.subType, 'setup');
        return setup?.outputAction;
    };
}

export default BrickSetupHelper;
