import DataSetType from 'types/dataset.type';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import { FeedRequest } from 'components/data/FeedManagementHelpers';
import ComponentStoreHelpers from 'components/data/ComponentStore';
import ComponentStore from 'components/data/ComponentStore';
import BrickDataService from '../services/brick-data.service';
import { Brick, BrickType } from '../types/brick.type';
import BrickHelpers from './brick.helpers';
import FeedMessagesService from '../services/feed-messages.service';
import BricksComponentStoreHelper from './bricks-component-store.helper';
import { BrickFeedProcess, FeedProcessStatus } from '../types/bricksComponentStore.type';

class BrickFeedHelpers {
    static generateFeedOutput = async (brickId: string): Promise<string | undefined> => {
        const brick = BrickHelpers.getBrickById(brickId);

        if (!brick?.id) {
            SnackbarUtils.error('No brick is selected for generating output');
            return;
        }

        const datasetId = brick?.data?.dataset?.datasetId;

        if (!datasetId) {
            SnackbarUtils.error('Please select a feed');
            return;
        }

        const feedProcessId = await BrickDataService.generateFeedOutput(brick.id, datasetId);

        if (!feedProcessId) {
            SnackbarUtils.error('Failed to generate output');
            return;
        }

        const feedSubscriptionId = FeedMessagesService.subscribe(feedProcessId);

        const feedBrickChildIds = BrickHelpers.getChildrenOfBrick(brick)
            .filter((child) => child.type !== BrickType.MASTER && child.type !== BrickType.MASTER_CHILD)
            .map((child) => child.id);
        BricksComponentStoreHelper.deleteMultipleBricks(feedBrickChildIds);

        const brickFeedProcess: BrickFeedProcess = {
            status: FeedProcessStatus.STARTING,
            processedRows: 0
        };

        ComponentStore.setMultiModels('Bricks', [
            [
                'feedMessages',
                [
                    {
                        feedProcessId: feedProcessId,
                        subscriptionId: feedSubscriptionId
                    }
                ]
            ],
            [`pagination.${brickId}.maxResultsReached`, false], // Reset maxResultsReached
            [`feedState.${brick.id}`, brickFeedProcess]
        ]);

        return feedProcessId;
    };

    /**
     * Loads dataset assigned to brick
     * @param brickId
     * @returns dataset of brick
     */
    public static loadBrickDataset = async (brickId: string): Promise<DataSetType> => {
        const brick = BrickHelpers.getBrickById(brickId);
        const datasetId = brick?.data?.dataset?.datasetId;
        const response = await FeedRequest.get(`dataset/${datasetId}`);
        const dataSet: DataSetType = response.data;
        return dataSet;
    };

    /**
     * Gets total count of children in first layer for parent brick
     * @param brickId
     * @returns count
     */
    public static calculateFeedFirstLayerChildrenCount = async (brickId: string): Promise<number> => {
        const dataset: DataSetType = await this.loadBrickDataset(brickId);
        if (!dataset) return Promise.resolve(0);
        const itemCount = dataset.metadata.itemCount || 0;

        const bricksOverview = ComponentStoreHelpers.get('Bricks')?.bricks || {};

        // Gets master bricks
        const masterParentBricksCount = Object.keys(bricksOverview).filter(
            (id) => bricksOverview[id].parentId === brickId && bricksOverview[id].type === BrickType.MASTER
        ).length;

        return masterParentBricksCount * itemCount;
    };

    /**
     * Gets the dataset id of parent feed brick
     * @param brick
     * @returns dataset id
     */
    static getDataSetIdOfParentFeedBrick = (brick: Brick): string | undefined => {
        const parentBrick = BrickHelpers.getBrickById(brick.parentId);

        if (!parentBrick?.parentId) return parentBrick?.data?.dataset?.datasetId;

        return this.getDataSetIdOfParentFeedBrick(parentBrick);
    };

    static cancelBrickFeedProcess = async (brickId: string): Promise<void> => {
        const brick = BrickHelpers.getBrickById(brickId);

        const datasetId = brick?.data?.dataset?.datasetId;

        if (!datasetId) return;

        const feedCancelationData = await BrickDataService.cancelFeedOutput(brickId, datasetId);

        if (feedCancelationData) return ComponentStore.setModel('Bricks', `feedState.${brick.id}`, undefined);
    };
}

export default BrickFeedHelpers;
