import ComponentStore from 'components/data/ComponentStore';
import { Brick, BrickSetup } from '../types/brick.type';
import { BricksComponentStore } from '../types/bricksComponentStore.type';
import { OutputAction } from '../types/publish.type';
import BrickHelpers from './brick.helpers';
import CustomBrickHelpers from './custom-brick-helper';
import ValidateHelpers from './validate.helpers';

class OutputHelper {
    static getOutputFormats = (brick: Brick, brickIds: string[]): Brick => {
        const selectedFormats = brickIds.filter((filteredKey) => filteredKey.startsWith(`${brick?.id}-`)); // Get only the selected formats for the brick.

        // Add formats for only creative bricks.
        if (brick?.subType.startsWith('creative') && selectedFormats.length) {
            const formats = selectedFormats.map((selectedKey) => selectedKey.split('-')[1]);

            // Ensure brick.data and brick.data.settings are defined
            brick.data ??= {};
            brick.data.settings ??= {};
            brick.data.settings.formats = formats; // Set only the selected formats.
        }

        return brick;
    };

    /**
     * Checks if the brick has creatives or selected children with creatives
     */
    static hasCreatives = (brick: Brick, selectedBricks: string[]): boolean => {
        if (brick.data?.creatives?.length > 0) return true;

        const brickComponentStore: BricksComponentStore | undefined = ComponentStore.get('Bricks');
        const bricks = brickComponentStore?.bricks;

        if (!bricks) return false;

        const children = BrickHelpers.getBrickAndInnerChildren(bricks, brick.id).map((brickId) => {
            const id = BrickHelpers.getBrickIdPrefix(brickId);
            return bricks[id];
        });
        return children.some((child) => selectedBricks.includes(child.id) && child.data?.creatives?.length > 0);
    };

    /**
     * Gets selected bricks
     * @returns selected bricks
     */
    static getBricksForOutput = (bricksId: string[], outputAction: OutputAction): Brick[] => {
        const selectedBricks: string[] = bricksId;

        // Make sure to include the parent brick if a custom brick is selected
        for (let i = 0; i < bricksId.length; i++) {
            const brick = BrickHelpers.getBrickById(bricksId[i]);
            if (!brick) continue;

            if (CustomBrickHelpers.isCustomBrick(brick) && brick.parentId && !bricksId.includes(brick.parentId)) {
                selectedBricks.push(brick.parentId);
            }
        }

        return selectedBricks.reduce<Brick[]>((acc, id) => {
            const brick = BrickHelpers.getBrickById(id);
            const brickSetup = BrickHelpers.getBrickData<BrickSetup | undefined>(brick?.subType, 'setup');

            if (!brick) return acc;

            const hasValidOutputAction = outputAction === 'publish' ? brickSetup?.outputAction === outputAction : true;
            const hasCreatives = outputAction === 'download' ? this.hasCreatives(brick, selectedBricks) : true; // If output action is 'download' then filter out bricks without creatives.
            const isNotCustomBrick = !CustomBrickHelpers.isCustomBrick(brick); // Filter out custom bricks and groups.
            const isValidBrick = outputAction === 'publish' ? !ValidateHelpers.isBrickWithValidationErrors(brick.id) : true; // If output action is 'publish' then filter out bricks with validation errors.

            if (hasCreatives && isNotCustomBrick && isValidBrick && hasValidOutputAction) {
                // Add creative formats to the brick if needed.
                acc.push(this.getOutputFormats(brick, bricksId));
            }

            return acc;
        }, []);
    };

    /**
     * Gets output action of bricks
     * @returns output action for bricks
     */
    static getOutputActionOfBricks = (bricks: Brick[]): 'publish' | 'download' | 'all' => {
        if (!bricks.length) return 'all';

        const firstAction = BrickHelpers.getBrickData<BrickSetup>(bricks[0].subType, 'setup').outputAction || 'all';

        for (const brick of bricks) {
            const currentAction = BrickHelpers.getBrickData<BrickSetup>(brick.subType, 'setup').outputAction;
            if (currentAction === 'all' || currentAction !== firstAction) {
                return 'all';
            }
        }

        return firstAction;
    };
}

export default OutputHelper;
